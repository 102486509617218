import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, throwError, Subject, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public options: any = {};
  public sapOptions: any = {};
  public httpOptions: any = {};
  public accessTokenReceived = new BehaviorSubject(false);
  public userData = new BehaviorSubject({});
  private userDataSub = new Subscription();
  public userDataSubject: Subject<{}> = new Subject<{}>();
  public showIDList=[];
  private MOCK_APPROVE_JSON_URL: string = '../assets/jsons/invoices-upload.json?v=' + Math.random();

  public getOptions = {};
  private baseUrl = '';

  constructor(private http: HttpClient) {
    this.baseUrl = '';
    this.userDataSub = this.userData.subscribe(res => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + (localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''),
          'UserId': localStorage.getItem('paceUserSSO') ? localStorage.getItem('paceUserSSO') : ''
        })
      };
      this.options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + (localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''),
          'UserId': localStorage.getItem('paceUserSSO') ? localStorage.getItem('paceUserSSO') : '',
          'Content-Type': 'application/json'
        })
      };
      this.sapOptions = {
        headers: new HttpHeaders({
          'Api-key': environment.sapApiKey,
          'Content-Type': 'application/json'
        })
      };
    });

    this.accessTokenReceived.subscribe(res => {
      this.getOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + (localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''),
          'Content-Type': 'application/json'
        })
      };
    });
  }



  get(url: string, headers?: HttpHeaders): Observable<any> {
    console.log('Log', url, headers);
    if (!headers) {
      headers = this.httpOptions.headers;
    }
    // tslint:disable-next-line:object-literal-shorthand
    return this.http.get(url, { headers: headers })
      .pipe(map(res => res),
        catchError(this.checkUnauthorized));
  }

  post(url: string, body?: any, headers?: HttpHeaders, formData?: FormData): Observable<any> {
    if (!headers) {
      headers = this.httpOptions.headers;
    }
    // tslint:disable-next-line:object-literal-shorthand
    return this.http.post(url, body, { headers: headers })
      .pipe(map(res => res),
        catchError(this.checkUnauthorized));
  }
  public serviceRequestCommon(method = 'get', URL = '', queryString = '', payLoad: any = {}) {
    const apiUrl = URL + queryString;
    console.log(apiUrl);
    // this.options = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json'
    //   })
    // };

    switch (method) {
      case 'get':
        return this.doGetService(apiUrl, this.options);
      case 'post':
        return this.doPostService(apiUrl, payLoad, this.options);
      case 'put':
        return this.doPutService(apiUrl, payLoad, this.options);
      case 'delete':
        return this.doDeleteService(apiUrl, this.options);
    }
  }

  private doPutService(apiUrl, payLoad, options) {
    return this.http.put(this.baseUrl + apiUrl, payLoad, options)
      .pipe(map(res => res),
        catchError(this.checkUnauthorized));
  }

  private doPostService(action, payLoad = {}, options) {
    return this.http.post(this.baseUrl + action, payLoad, options)
      .pipe(map(res => res),
        catchError((error: HttpErrorResponse) => this.checkUnauthorized(error)));
  }

  private doDeleteService(apiUrl, options) {
    return this.http.delete(this.baseUrl + apiUrl, options)
      .pipe(map(res => res),
        catchError(this.checkUnauthorized));
  }

  public doGetService(apiUrl, options) {
    return this.http.get(this.baseUrl + apiUrl, options)
      .pipe(map(res => res),
        catchError(this.checkUnauthorized));
  }

  validateGLAccountWBSEWithSAP(queryString = '') {
    const url = environment.sapURL + queryString;
    // return this.serviceRequestCommon('get', this.MOCK_APPROVE_JSON_URL);
    return this.http.get(url, {
      responseType: 'json',
      headers: new HttpHeaders({
        'Api-key': environment.sapApiKey,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    }).pipe(map(res => res));
    // ,catchError(this.checkUnauthorized)
  }

  uploadfileAWSS3(fileuploadurl, contentType, file): Observable<any> {
    //this will be used to upload all csv files to AWS S3
    return this.http.put<Blob>(fileuploadurl, file, { headers: new HttpHeaders({ 'Content-Type': contentType, 'Content-Disposition': 'pdf/' + file.name }) });
  }

  downloadPdfFile(url): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }



  public fileDownload(apiUrl, payLoad): Observable<any> {
    return this.http
      .post(apiUrl, payLoad, {
        responseType: 'blob',
        observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Authorization': 'Bearer ' + (localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''),
          'UserId': localStorage.getItem('paceUserSSO') ? localStorage.getItem('paceUserSSO') : '',
        })
      }).pipe(map(res => res),
        catchError(this.checkUnauthorized));
    // .map((response: Blob) => {
    //   return response;
    // })
    // .catch((error: Response) => {
    //   return Observable.throw(error);
    // });
  }
  public fileDownloadWithoutPayload(apiUrl): Observable<any> {
    return this.http
      .get(apiUrl, {
        responseType: 'blob',
        observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Authorization': 'Bearer ' + (localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''),
          'UserId': localStorage.getItem('paceUserSSO') ? localStorage.getItem('paceUserSSO') : '',
        })
      }).pipe(map(res => res),
        catchError(this.checkUnauthorized));
    // .map((response: Blob) => {
    //   return response;
    // })
    // .catch((error: Response) => {
    //   return Observable.throw(error);
    // });
  }
  public downloadTemplate(apiUrl): Observable<any> {
    return this.http
      .get(apiUrl, {
        responseType: 'blob',
        observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Authorization': 'Bearer ' + (localStorage.getItem('access_token') ? localStorage.getItem('access_token') : ''),
          'UserId': localStorage.getItem('paceUserSSO') ? localStorage.getItem('paceUserSSO') : '',
        })
      }).pipe(map(res => res),
        catchError(this.checkUnauthorized));
  }

  checkUnauthorized(error: HttpErrorResponse) {
    if (error.status === 401 || error.status === 403) {
      localStorage.clear();
      localStorage.setItem('referenceUrl', window.location.href.replace(environment.appURL, ''));
      window.location.replace('https://' + environment.authURL + '/as/authorization.oauth2?client_id=' + environment.clientId + '&response_type=token id_token&redirect_uri=' + environment.appURL + '&scope=' + environment.scope);
    }
    else {
      return throwError(error);
    }
    return null;
  }

  checkSession() {
    const sessionTime = new Date(localStorage.getItem('timeout'));
    const currentTime = new Date();
    if (currentTime > sessionTime) {
      localStorage.clear();
      localStorage.setItem('referenceUrl', window.location.href.replace(environment.appURL, ''));
      window.location.replace('https://' + environment.authURL + '/as/authorization.oauth2?client_id=' + environment.clientId + '&response_type=token id_token&redirect_uri=' + environment.appURL + '&scope=' + environment.scope);
      return null;
    }
  }

  public getUserProfile(token) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + token);
    let url = environment.userBasePath + 'users/token';
    // tslint:disable-next-line:object-literal-shorthand
    return this.http.post(url, { token: token }, { headers: headers });
  }
  public getLoggedInSSO() {
    return localStorage.getItem('paceUserSSO');
  }
  // ngOnDestroy() {
  //   this.userDataSub.unsubscribe();
  // }
}
